import React, { useState } from "react";
//= Layout
import MainLayout from "layouts/Main";
//= Components
import Navbar from "components/Navbars/ITCreativeNav";
import ChatBanner from "components/Software/ChatBanner";
import Footer from "components/Saas/Footer";
import { Link } from "gatsby";
import Codei from "components/Preview/Codei";


const MtGreenSolar = () => {

  const [isOpen, setOpen] = useState(false);

  const openVideo = (e) => {
    e.preventDefault();
    setOpen(true);
  }
  return (
    <MainLayout>
      <Navbar />
      <header className="style-3 overflow-hidden" data-scroll-index="0" style={{background: '#010049'}}>
      <div className="container">
        <div className="content section-padding">
          <div className="row">
            <div className="col-lg-5">
              <div className="info" style={{ background: '#00000000'  }}>
                <h1 className="h1">Mt Green <br /><span> Solar  </span></h1>
                <p className="p">Power your home or business sustainably with MT Green Solar </p>                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-img ">       
        <img src="/assets/img/about/style_3_1.png" style={{ maxWidth: '30%'}} alt="" />
        <img
                    src="/assets/img/about/MTGREEN.png"
                    alt=""
                    className="img-body "
                  />
      </div>
    </header>
      <main>
        <section
          className="about section-padding style-3"
          data-scroll-index="3"
        >
          <div className="container">
            <div className="row gx-0 justify-content-end">
              <div className="col-lg-6">
                <div className="img  ">
                  <img
                    src="/assets/img/about/MTGREEN.png"
                    alt=""
                    className="img-body "
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="">
                  <div className="section-head long-shape mb-40 style-3">
                    <h3>MT Green Solar</h3>
                  </div>
                  <p>
                  Power your home or business sustainably with MT Green Solar, our solar asset financing solution. We provide flexible financing options for purchasing solar energy systems, helping you reduce electricity costs and embrace clean energy. Our financing plans are designed to help you go green without the upfront financial burden.
                  </p>
                  <div className="text mb-20 mt-20">
                    <h5>Requirements: </h5>
                    <ul>
                      <li>6 Months Bank Statement: We will need the last six months account statement of your business.</li>
                      <li>Collateral Documents: Original collateral documents are also needed.</li>
                      <li>Valid Identification: We will need your passport and any valid means of identification for KYC purposes.</li>
                   
                    </ul>
                  </div>
                  <a
                    href="https://zfrmz.com/OWzvdPzK37U5QjSkVnFE"
                    className="btn rounded-pill bg-blue2 sm-butn text-white mt-10"
                  >
                    <span>Apply for MT Green Solar</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <Blog8 /> */}
        <Codei /> 
        <ChatBanner />
      </main>
      <Footer />
    </MainLayout>
  );
};

export const Head = () => {
  return (
    <>
      <title> Power Your Future - Make the Switch to Solar with MT Green</title>
<meta name="description" content=" MTGreen provides you with affordable solar financing options to help you go green. Power your home, shops, or offices with clean, uninterrupted renewable energy
" />
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  );
};

export default MtGreenSolar;
